// import '../css/app.scss'
// let's not do this. avoids flash of unstyled content.
// see the head tag for how we are loading this css

import './components/new-navigation.js';
import './components/search.js';
import './components/sticky.js';

// import './components/nav-widget.js';

document.querySelectorAll( 'oembed[url]' ).forEach( element => {
    // Create the <a href="..." class="embedly-card"></a> element that Embedly uses
    // to discover the media.
    const anchor = document.createElement( 'a' );

    anchor.setAttribute( 'href', element.getAttribute( 'url' ) );
    anchor.className = 'embedly-card';

    element.appendChild( anchor );
} );

